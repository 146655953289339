<template>
	<div class="gonglue-gl">
		<div class="gonglue-gla">
			<!-- 攻略头部轮播图 -->
			<el-carousel :interval="5000" arrow="never">
				<el-carousel-item v-for="(item,index) in url" :key="index">
					<div class="gonglue-glb">
						<img :src="item.urla" class="gonglue-img">
						<div class="gonglue-glc">
							<div class="gonglue-gld">{{item.urlb}}</div>
							<div class="gonglue-gle">
								<div class="gonglue-glf">{{item.urlc}}&nbsp;</div>
								<div class="gonglue-glg">{{item.urld}}&nbsp;</div>
								<div class="gonglue-glf">{{item.urle}}</div>
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		
		<div class="gonglue-glh">
			<div class="gonglue-gli">
				<!-- 攻略 全部旅游攻略 -->
				<div class="gonglue-glj">全部旅游攻略</div>
				<a href="" class="gonglue-glk">
					<span><img src="../../assets/youjibi.png" class="gonglue-bi"></span>
					<span class="gonglue-glkk">写攻略</span>
				</a>
			</div>
			<div class="gonglue-gll">
				<!-- 攻略 国内攻略 第一行 -->
				<div class="gonglue-glm">国内攻略:</div>
				<div class="" v-for="(item,index) in guonei" :key="index" :class="[guoneia==index?'gonglue-glnn':'gonglue-gln']" @click="btn(index)">
					<div class="">{{item.guo}}</div>
					<div class="">{{item.nei}}</div>
				</div>
			</div>
			<div class="gonglue-gll">
				<!-- 攻略 国内攻略 第二行 -->
				<div class="gonglue-glm">国内攻略:</div>
				<div class="" v-for="(item,index) in guoji" :key="index" :class="[guojia==index?'gonglue-glnn':'gonglue-gln']" @click="btnn(index)">
					<div class="">{{item.guo}}</div>
					<div class="">{{item.nei}}</div>
				</div>
			</div>
			<div class="gonglue-gll" :class="{'gonglue-gll':!show,'gonglue-glll':show}">
				<!-- 攻略 国内攻略 第三行 -->
				<div class="gonglue-glm">国内攻略:</div>
				<div class="" :class="{'gonglue-glp':!show,'gonglue-glpp':show}">
					<div class="" v-for="(item,index) in zhuti" :key="index" :class="[zhutia==index?'gonglue-glnn':'gonglue-gln']" @click="btnnn(index)">
						<div class="">{{item.guo}}</div>
						<div class="">{{item.nei}}</div>
					</div>
					<div class="gonglue-glo" v-show="show==1" @click.stop="show=!show">
						<div class="">收起</div>
						<div class=""><img src="../../assets/cujiantoua.png" class="gonglue-jt"></div>
					</div>
				</div>
				<div class="gonglue-glo" v-show="show==0" @click.stop="show=!show">
					<div class="">更多</div>
					<div class=""><img src="../../assets/cujiantou.png" class="gonglue-jt"></div>
				</div>
			</div>
			<div class="gonglue-glq" >
				<!-- 热门攻略 最新发表 精品攻略 选项卡 -->
				<div class="" v-for="(item,index) in renmen" :key="index" :class="[renmena==index?'gonglue-glr':'gonglue-glrr']" @click="btnnnn(index)">
					<div class="">{{item}}</div>
				</div>
			</div>
			
			<div class="">
				<!-- 热门攻略 最新发表 精品攻略内容-->
				<div class="" v-if="renmena==0">热门攻略</div>
				<div class="" v-if="renmena==1">最新发表</div>
				<div class="" v-if="renmena==2">精品攻略</div>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');
	//接口	
	var wqgApi = require('@/api/wqgApi.js');	
	//组件引用
	
	//公共属性
	var that;
	
	export default {
		components: {
			//组件
			
		},
		data() {
			return {
				id: '',
				title:"社区首页",
				url: [{
						urla: 'https://img8.yiqifei.com/20200304/99abdbc8355d479ea74a840eeb4c92b0.jpg!/both/1000x350',
						urlb: '里斯本--伊比利亚半岛旅行的最佳开篇',
						urlc: '本游记攻略游小猴子荣誉出品！'
						// urld: '一起飞王楚钦',
						// urle: '荣誉出品！'
					},
					{
						urla: 'https://img8.yiqifei.com/20200305/b513dc754e164ebd938fcbad6c5a0f99.jpg!/both/1000x350',
						urlb: '时间流过爱的河: 维罗纳, 一座刻画着无法拥有的爱的城市',
						urlc: '本游记攻略游我是yoyo猫眼看世界荣誉出品！'
						// urld: '一起飞王楚钦',
						// urle: '荣誉出品！'
					},
					{
						urla: 'https://img8.yiqifei.com/20200306/d5c5d25ca0d744a18fe953ca6d293dfb.jpg!/both/1000x350',
						urlb: '秘境自驾孟屯河谷, 3日自驾穿越之旅',
						urlc: '本游记攻略游13284****65荣誉出品！'
						// urld: '一起飞王楚钦',
						// urle: '荣誉出品！'
					}
				],
				guoneia:0,
				guonei:[
					{guo:'全部'},
					{guo:'广东',nei:'（2）'},
					{guo:'福建',nei:'（2）'},
					{guo:'香港特别行政',nei:'（1）'},
					{guo:'湖南',nei:'（1）'},
					{guo:'台湾',nei:'（1）'},
					{guo:'澳门',nei:'（1）'}
				],
				guojia:0,
				guoji:[
					{guo:'全部'},
					{guo:'南美洲',nei:'（1）'},
					{guo:'东南亚',nei:'（5）'},
					{guo:'北美洲',nei:'（6）'},
					{guo:'大洋洲',nei:'（1）'},
					{guo:'中亚',nei:'（2）'},
					{guo:'欧洲',nei:'（3）'},
					{guo:'东北亚',nei:'（4）'}
				],
				show:false,
				zhutia:0,
				zhuti:[
					{guo:'全部'},
					{guo:'文化',nei:'（18）'},
					{guo:'美食',nei:'（19）'},
					{guo:'都市',nei:'（17）'},
					{guo:'购物',nei:'（14）'},
					{guo:'海滩',nei:'（11）'},
					{guo:'摄影',nei:'（10）'},
					{guo:'寺庙',nei:'（9）'},
					{guo:'建筑',nei:'（8）'},
					{guo:'亲子',nei:'（8）'},
					{guo:'艺术',nei:'（7）'},
					{guo:'古镇',nei:'（7）'},
					{guo:'潜水',nei:'（7）'},
					{guo:'蜜月',nei:'（7）'},
					{guo:'岛屿',nei:'（7）'},
					{guo:'历史',nei:'（6）'},
					{guo:'森林',nei:'（5）'},
					{guo:'温泉',nei:'（5）'},
					{guo:'主题公园',nei:'（4）'},
					{guo:'湖泊',nei:'（4）'},
					{guo:'草原',nei:'（4）'},
					{guo:'名山',nei:'（4）'},
					{guo:'徒步',nei:'（4）'},
					{guo:'赏花',nei:'（3）'},
					{guo:'登山',nei:'（3）'},
					{guo:'漂流',nei:'（2）'},
					{guo:'滑雪',nei:'（2）'},
					{guo:'节庆',nei:'（2）'},
					{guo:'动物',nei:'（2）'},
					{guo:'泡吧',nei:'（2）'},
					{guo:'洞穴',nei:'（2）'},
					{guo:'沙漠',nei:'（2）'},
					{guo:'雪山',nei:'（2）'},
					{guo:'峡谷',nei:'（2）'},
					{guo:'火山',nei:'（2）'},
					{guo:'观鸟',nei:'（1）'},
					{guo:'湿地',nei:'（1）'},
				],
				renmena:0,
				renmen:['热门攻略','最新发表','精品攻略']
			};
		},
		created() {
			that = this;
		},
		methods: {
			btn(index){
				// 全部旅游攻略-国内攻略第一行事件
				this.guoneia=index
				console.log(index)
			},
			btnn(index){
				// 全部旅游攻略-国内攻略第二行事件
				this.guojia=index
				console.log(index)
			},
			btnnn(index){
				// 全部旅游攻略-国内攻略第三行事件
				this.zhutia=index
				console.log(index)
			},
			btnnnn(index){
				// 热门攻略 最新发表 精品攻略选项卡事件
				this.renmena=index
				console.log(index)
			}
		}
	};
</script>

<style lang="scss">
	@import '../../style/youji/gonglue.scss';
</style>
